.card {
  display: flex;
  &:hover {
    color: #ff3131;
    transform: scale(1.06);
    cursor: pointer;
  }
}

.cardContent {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
