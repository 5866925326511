.container {
  height: 100%;

  .box {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .buttonContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .containerCards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      margin-top: 8px;

      .cardButton {
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
          background: #fff;
        }
      }
    }
  }
}

.card {
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .container {
    cursor: pointer;

    .cardHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      gap: 8px;

      .buttonRemove {
        height: 30px;
        align-self: flex-end;
      }
    }

    .cardBody {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 16px 0;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        strong {
          font-weight: bold;
        }
      }
    }
  }

  .cardFooter {
    height: 30px;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    background: orangered;
    color: white;

    &:hover {
      background: orange;
      cursor: pointer;
    }
  }
}
