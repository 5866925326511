.container {
  flex: 1;

  .paper {
    border-radius: 0;
    height: 100%;

    .form {
      height: 100;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;

      .button {
        margin-top: 16px;
      }
    }
  }
}
