.container {
  color: white;
  height: calc(100% - 45px);
  display: flex;
  flex-direction: column;

  img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 50%;
  }

  .header {
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;

    h1 {
      font-size: 2em;
      margin-bottom: 24px;
      text-align: center;
    }

    .principal {
      height: 150px;
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 4px solid white;
      font-size: 1.8em;
      margin-bottom: 30px;
    }
  }

  .infos {
    background-color: white;
    color: black;
    border-radius: 18px 18px 0 0;
    display: flex;
    flex-direction: column;
    padding: 32px 20px;
    gap: 24px;
    flex: 1;
    position: relative;
    top: -40px;

    .item {
      display: flex;
      align-items: center;
      gap: 24px;

      .box {
        width: 1.5em;
        height: 1.5em;
        border-radius: 8px;
        background-color: #333333;
      }

      .text {
        text-align: left;
        flex: 1;
      }

      .value {
        font-weight: bold;
        margin-left: auto;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    .infos {
      .item {
        .text {
          width: 150px;
        }
      }
    }
  }
}
