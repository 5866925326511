.container {
    padding: 60px 0;

    .loginContainer {
        border-radius: 10px;
        width: 445px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        font-family: sans-serif;
        overflow: hidden;
        margin: 0 auto;
        
        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }

        .loginContent {

            display: flex;
            flex-direction: column;
            padding: 48px;
            gap: 20px;

            .bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .register{
                display: flex;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .container {
        padding: 0;
        .loginContainer {
            border-radius: 0;
            width: 100%;
            box-shadow: none;

            img{
                height: 170px;
            }

            .loginContent {
                padding: 24px;
            }
        }
    }
}